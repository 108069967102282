<template>
    <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">Paid Stock Photo Items </h1>
            </div>
            <div class="search">
            
            </div>
            <div class="table-responsive">
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Price</th>
                            <th scope="col">Data</th>
                            <th scope="col">Stock Photo</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="products.length">
                        <tr v-for="(product, index) in products" :key="product.id">
                            <th scope="row">{{product.id}}</th>
                            <td>{{product.price}}</td>
                            <td>{{product.created_at | dateType2}}</td>
                            <td><img :src="`${apiDomain}/${product.stock_photo.watermark}`" height="70px" width="70px" alt="" srcset=""></td>
                            <td>
                                <div>
                                    <span><button type="button" @click="downloadPhoto(product.id, product.title)" class="btn btn-success btn-circle btn-sm"><i class="fa fa-download"></i></button> </span>
                                    <span></span>
                                </div>
                               <!--  <div class="row">
                                    <div class="col-md-2 col-2">a</div>
                                    <div class="col-md-2 col-2">a</div>
                                    <div class="col-md-2 col-2">a</div>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>

            
            <div v-if="!products.length">
                <div class="empty-products" style="text-align:center;padding: 70px 0 ">
                    <div class="empty-img">

                        <svg xmlns="http://www.w3.org/2000/svg" height="80px" version="1.1" viewBox="0 0 512.00002 512.00002" width="80px">
                        <g id="surface1">
                        <path d="M 512 88.277344 L 0 88.277344 L 0 512 L 512 512 Z M 317.792969 397.242188 L 194.207031 397.242188 L 194.207031 256 L 156.75 293.453125 L 194.207031 256 L 231.664062 293.453125 L 194.207031 256 L 194.207031 397.242188 L 317.792969 397.242188 L 317.792969 256 L 280.335938 293.453125 L 317.792969 256 L 355.25 293.453125 L 317.792969 256 Z M 317.792969 397.242188 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,85.490196%,66.666667%);fill-opacity:1;" />
                        <path d="M 256 0 L 52.964844 0 L 0 88.277344 L 256 88.277344 Z M 256 0 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,76.862745%,46.666667%);fill-opacity:1;" />
                        <path d="M 512 88.277344 L 459.035156 0 L 256 0 L 256 88.277344 Z M 512 88.277344 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,71.372549%,33.333333%);fill-opacity:1;" />
                        <path d="M 326.621094 141.242188 L 185.378906 141.242188 C 180.503906 141.242188 176.550781 137.289062 176.550781 132.414062 C 176.550781 127.539062 180.503906 123.585938 185.378906 123.585938 L 326.621094 123.585938 C 331.496094 123.585938 335.449219 127.539062 335.449219 132.414062 C 335.449219 137.289062 331.496094 141.242188 326.621094 141.242188 Z M 326.621094 141.242188 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,71.372549%,33.333333%);fill-opacity:1;" />
                        <path d="M 388.414062 406.070312 L 123.585938 406.070312 C 118.710938 406.070312 114.757812 402.117188 114.757812 397.242188 C 114.757812 392.367188 118.710938 388.414062 123.585938 388.414062 L 388.414062 388.414062 C 393.289062 388.414062 397.242188 392.367188 397.242188 397.242188 C 397.242188 402.117188 393.289062 406.070312 388.414062 406.070312 Z M 388.414062 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 194.207031 406.070312 C 189.332031 406.070312 185.378906 402.117188 185.378906 397.242188 L 185.378906 256 C 185.378906 251.125 189.332031 247.171875 194.207031 247.171875 C 199.082031 247.171875 203.035156 251.125 203.035156 256 L 203.035156 397.242188 C 203.035156 402.117188 199.082031 406.070312 194.207031 406.070312 Z M 194.207031 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 231.664062 302.28125 C 229.320312 302.28125 227.078125 301.351562 225.421875 299.695312 L 194.207031 268.480469 L 162.992188 299.695312 C 159.605469 303.203125 154.015625 303.300781 150.511719 299.914062 C 147.003906 296.527344 146.90625 290.9375 150.292969 287.429688 C 150.363281 287.355469 150.4375 287.285156 150.511719 287.214844 L 187.964844 249.757812 C 191.414062 246.3125 197 246.3125 200.449219 249.757812 L 237.902344 287.214844 C 241.351562 290.660156 241.347656 296.25 237.902344 299.699219 C 236.246094 301.351562 234.003906 302.28125 231.664062 302.28125 Z M 231.664062 302.28125 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 317.792969 406.070312 C 312.917969 406.070312 308.964844 402.117188 308.964844 397.242188 L 308.964844 256 C 308.964844 251.125 312.917969 247.171875 317.792969 247.171875 C 322.667969 247.171875 326.621094 251.125 326.621094 256 L 326.621094 397.242188 C 326.621094 402.117188 322.667969 406.070312 317.792969 406.070312 Z M 317.792969 406.070312 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        <path d="M 355.25 302.28125 C 352.90625 302.28125 350.664062 301.351562 349.007812 299.695312 L 317.792969 268.480469 L 286.578125 299.695312 C 283.070312 303.082031 277.484375 302.988281 274.097656 299.480469 C 270.792969 296.058594 270.792969 290.636719 274.097656 287.214844 L 311.550781 249.757812 C 315 246.3125 320.585938 246.3125 324.035156 249.757812 L 361.488281 287.214844 C 364.9375 290.664062 364.933594 296.253906 361.488281 299.699219 C 359.832031 301.351562 357.589844 302.28125 355.25 302.28125 Z M 355.25 302.28125 " style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,31.372549%,13.72549%);fill-opacity:1;" />
                        </g>
                        </svg>
                    </div>
                    <p style="color:grey;font-size:14px; padding-top:10px">
                        No categories found
                    </p>
                </div>
            </div>
            
        </div>
        <base-delete-modal @deleteItem="deleteProduct" :name="bin.name"/>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    import BaseDeleteModal from '../../components/BaseDeleteModal.vue'
    import {axios} from '../../services/axios.js'
    export default {
        data() {
            return {
                products: [],
                apiDomain: process.env.VUE_APP_API_DOMAIN,
                public_path: `${process.env.VUE_APP_API_DOMAIN}/files/products/`,

                id: this.$route.params.id,
                empty: false,
                public_path: `${process.env.VUE_APP_API_DOMAIN}/files/products/`,
                bin: {
                    id: 0,
                    name: "",
                    index: null,

                }
            }
        },
        components: {
            BaseDeleteModal
        },
        methods: {
            moveToBin(id,name, index) {
                this.bin = {
                    id, name, index
                }
            },
            async getProducts() {
                this.$store.dispatch('setLoading',true)
                try {
                    var products = await Crud.show('admin/orders', this.id)
                    this.products = products.order_items
                    
                   
                    console.log(this.products)
                
                    this.$store.dispatch('setLoading',false)
                    if(!this.products.length) {
                        this.empty = true
                    }
                } catch (e) {
                    this.$store.dispatch('setLoading',false)

                }
            },
            deleteProduct() {
                this.$store.dispatch('setLoading',true)
                Crud.delete('admin/categories',this.bin.id)
                .then((res) => {
                    console.log(res)
                    this.$store.dispatch('setLoading',false)

                    //this.$store.dispatch('success',`${this.bin.name} deleted`)
                    this.products = this.products.filter(item => item.id != this.bin.id)
                    this.$toast.success(`${this.bin.name} deleted`);

                    //this.$router.go(-1)
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.dispatch('setLoading',false)
                })

            },
            downloadPhoto(id, title) {
                let url = this.apiDomain + `/api/admin/photos/download/${id}`;
                const method = "GET";
                axios.request({
                    url,
                    method,
                    responseType: "blob", //important
                })
                .then(({ data }) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                    // const extension = JSON.stringify(data);
                    const exten = data.type;
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.setAttribute(
                        "download",
                        `${title}.${exten.substring(exten.indexOf("/") + 1)}`
                    ); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            },
            route(path) {
                this.$router.push(path)
            },
            loader() {
                this.$store.dispatch('setLoading',true)
            }
        },
        created() {
           
            this.getProducts()
            $('.carousel').carousel({
                interval: 2000
            })
        }
    }
</script>

<style lang="scss" scoped>
.search {
    padding-bottom:30px;
}
.product {
    .card {
        background-color: #ffffff;
        border-radius: 20px
    }

    p {
        color: #b7b1b1;
        font-weight: 500
    }

    .discBtn {
        background-color: #21afa7;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500
    }

    .fa-heart {
        color: #f05c5d
    }

    .fa-balance-scale {
        color: #968888
    }

    .fa-arrows {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg)
    }

    .buy {
        border-radius: 20px;
        background-color: #e8f6f6;
        color: #2bc4ba
    }

    .cart {
        background-color: #2bc4ba;
        border-radius: 30px;
        position: relative;
        right: 35px
    }

    .btn:focus {
        outline: none;
        box-shadow: none
    }

    *:focus {
        outline: none
    }
}
    .carousel-control-next,
    .carousel-control-prev {
        filter: invert(100%);
    }
</style>