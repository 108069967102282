<template>
    <div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">{{product.username}}</h1>
                <span>
                    <button href="#modalDelete" data-toggle="modal" class="btn btn-sm btn-info shadow-sm trigger-btn"><i class="fa fa-edit fa-sm text-white-50"></i>Payments</button>
                    &nbsp;
                    <button :to="`/panel/products/${product.id}/edit`" href="#modalDelete" data-toggle="modal" class="btn btn-sm btn-danger shadow-sm trigger-btn"><i class="fa fa-trash fa-sm text-white-50"></i>{{product.username}}'s downloads</button>
                    &nbsp;
                    <router-link :to="`/panel/products/${product.id}/edit`" class=" d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fa fa-edit fa-sm text-white-50"></i> dev</router-link>
                </span>
            </div>
            <p class="mb-4">Manage all your products here. <a target="_blank" href="">Click here for support</a>.</p>
        </div>
       <!--  <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div> -->
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h3 class="h3 mb-0 text-gray-800">{{product.username}} Information</h3>
                
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputEmail4">Name</label>
                    <input type="text"   v-model="product.username" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputEmail4">Email</label>
                    <input type="text"   v-model="product.email" class="form-control" id="inputEmail4" placeholder="">
                </div>
                
                    
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Momo Number</label>
                    <input type="text"   v-model="product.momo" class="form-control" id="inputEmail4" placeholder="">
                </div>
                 <div class="form-group col-md-4">
                    <label for="inputEmail4">Phone Number</label>
                    <input type="text"   v-model="product.phone" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Type</label>
                    <input type="text"   v-model="product.type" class="form-control" id="inputEmail4" placeholder="">
                </div>
                    
            </div>
             <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Charge Per Day</label>
                    <input type="text"   v-model="product.charge_day" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Charge Per Hour</label>
                    <input type="text"   v-model="product.charge_hour" class="form-control" id="inputEmail4" placeholder="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputEmail4">Minimum Charge Per Hour</label>
                    <input type="text"   v-model="product.min_charge__hour" class="form-control" id="inputEmail4" placeholder="">
                </div>
                    
            </div>
            <div class="form-row">
            <div class="form-group col-md-12">
                <label for="inputEmail4">Experience</label>
                <textarea class="form-control" name="" id="" cols="30" rows="5">
                    {{product.experience}}
                </textarea>
            </div>
            </div>
            <div class="form-row">
            <div class="form-group col-md-12">
                <label for="inputEmail4">Description</label>
                <textarea class="form-control" name="" id="" cols="30" rows="5">
                    {{product.description}}
                </textarea>
            </div>
            </div>
             <div class="form-row">
            <div class="form-group col-md-12">
                <label for="inputEmail4">Home Address</label>
                <textarea class="form-control" name="" id="" cols="30" rows="5">
                    {{product.home_address}}
                </textarea>
            </div>
            </div>
        </div>
        <div class="container-fluid">

        <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4" style="padding-top:20px">
                <h1 class="h3 mb-0 text-gray-800">Creative Activities</h1>
            </div>
            <p class="mb-4">Stock Photo Uploads of <b>[{{product.username}}]</b>.</p>
        </div>
        <div v-if="!photos.length" class="empty-products" style="text-align:center;padding: 70px 0 ">
            <div class="empty-img">

                

                <svg id="Flat" height="80px" viewBox="0 0 512 512" width="80px" xmlns="http://www.w3.org/2000/svg"><path d="m280 192a8 8 0 0 1 -8-8v-80a56 56 0 0 0 -112 0v80a8 8 0 0 1 -16 0v-80a72 72 0 0 1 144 0v80a8 8 0 0 1 -8 8z" fill="#dadcde"/><path d="m296 472h-272l16-320h240z" fill="#eb423f"/><path d="m360 472h-64l-16-320h64z" fill="#d13330"/><path d="m328.47 431.99c-.16.01-.32.01-.48.01a8 8 0 0 1 -7.98-7.53l-16-272c-.01-.16-.01-.31-.01-.47h16.02l15.97 271.53a8.007 8.007 0 0 1 -7.52 8.46z" fill="#a82a27"/><path d="m488 472h-64l-1-12.93v-.01l-15-195.06h64l14.75 191.69v.01z" fill="#eba72e"/><path d="m456.8 431.96a7.322 7.322 0 0 1 -.81.04 8 8 0 0 1 -7.95-7.2l-.29-2.86-15.71-157.14a7.143 7.143 0 0 1 -.04-.8h16.04l15.72 157.21.2 1.99a8 8 0 0 1 -7.16 8.76z" fill="#e09f2c"/><path d="m488 472h-9.62l-22.38-33.58-22.38 33.58h-9.62l-1-12.93v-.01l24.75-37.12 1.59-2.38a8.01 8.01 0 0 1 13.32 0l1.1 1.65 22.99 34.48v.01z" fill="#e09f2c"/><path d="m424 472h-208l16-208h176z" fill="#f7b030"/><path d="m328 416h-16a40.045 40.045 0 0 1 -40-40v-64a8 8 0 0 1 16 0v64a24.027 24.027 0 0 0 24 24h16a24.027 24.027 0 0 0 24-24v-64a8 8 0 0 1 16 0v64a40.045 40.045 0 0 1 -40 40z" fill="#e9eef2"/><path d="m96 184h16v48h-16z" fill="#dadcde"/><path d="m104 192a8 8 0 0 1 -8-8v-80a72 72 0 0 1 144 0v80a8 8 0 0 1 -16 0v-80a56 56 0 0 0 -112 0v80a8 8 0 0 1 -8 8z" fill="#e9eef2"/><path d="m128 296h-48v-64l24-16 24 16z" fill="#f7b030"/></svg>
            
            </div>
            <p style="color:grey;font-size:14px; padding-top:10px">
                {{product.username}} doesn't have any activities yet
            </p>
        </div>
        <div class="container">
        <div class="table-responsive">
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Watermark Image</th>
                        <th scope="col">Title</th>
                        <th scope="col">Price</th>
                        <th scope="col">Free</th>
                        <th scope="col">Downlods</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                        <th scope="col">Mark As</th>
                    </tr>
                </thead>
                <tbody v-if="photos.length">
                    <tr v-for="(photo, index) in photos" :key="photo.id">
                        <th scope="row"><div class="centered">{{photo.id}}</div></th>
                        <td><img :src="`${apiDomain}/${photo.watermark}`" height="70px" width="70px" alt="" srcset=""></td>
                        <td>{{photo.title}}</td>
                        <td>{{photo.price}}</td>
                        <td>{{photo.free}}</td>
                        <td>{{photo.downloads}}</td>
                        <td>{{photo.status}}</td>
                        <td>
                            <div>
                                <span><button type="button" @click="route('/panel/categories/'+photo.id+'/view')" class="btn btn-primary btn-circle btn-sm"><i class="fa fa-eye"></i></button> </span>
                                <span style="padding-left:5px"><button type="button" class="btn btn-info btn-circle btn-sm"><i class="fa fa-edit"></i></button> </span>
                                <span style="padding-left:5px"><button type="button" href="#modalDelete" @click="moveToBin(photo.id,photo.title, index)" data-toggle="modal" class="btn btn-danger btn-circle btn-sm"><i class="fa fa-trash"></i></button> </span>
                                <span></span>
                            </div>
                        </td>
                        <td v-if="photo.status == 'active' && !photo.loading"><button @click="changeStatus(photo.id, photo.title, 'inactive')" class="btn btn-info">Mark as Inactive</button></td>
                        <td v-if="photo.status == 'inactive' && !photo.loading"><button @click="changeStatus(photo.id, photo.title, 'active')" class="btn btn-warning">Mark as Active</button></td>
                        <td v-if="photo.loading"> <i class="fa fa-spinner fa-spin"></i></td>
                    </tr>
                </tbody>
                    
            </table>
        </div>
        </div>
        <base-pagination v-if="photos.length" :pagination="pagination" @fetchNext="fetchNext"/>

        <base-delete-modal @deleteItem="deleteProduct" :name="product.username"/>
    </div>
</template>

<script>
    import Crud from '../../services/main/CRUD.js'
    import VueApexCharts from 'vue-apexcharts'
    import BaseDeleteModal from '../../components/BaseDeleteModal.vue'
    import BasePagination from '../../components/BasePagination.vue'
    import PaginationMixin from '../../mixins/paginationMixin.js'
    import {axios} from '../../services/axios.js'

    export default {
        data() {
            return {
                id: this.$route.params.id,
                product: {
                    name: "",
                },
                photos: {}
          
          
            }
        },
        components: {
            apexchart: VueApexCharts,
            BaseDeleteModal,
            BasePagination
        },
        mixins: [PaginationMixin],
        
        methods: {
            changeStatus(id, name, type) {
                this.setProductStatusLoader(id, true)
                Crud.index(`admin/photos/change-status/${id}/${type}`)
                .then((res) => {
                    console.log(res)
                    
                    this.$toast.success(`${name} status set to ${type}`);

                    this.photos = this.photos.filter((item) => {
                        if(item.id == id) {
                            item.status = type
                        }
                        return item
                    })
                    this.setProductStatusLoader(id, false)
                })
                .catch((err) => {
                    console.log(err)
                    this.setProductStatusLoader(id, false)
                })
              


            },
            setProductStatusLoader(id, state) {
                this.photos = this.photos.filter((item) => {
                    if(item.id == id) {
                        item.loading = state
                    }
                    return item
                })
                //console.log(product)
            },

            deleteProduct() {
                this.$store.dispatch('setLoading',true)
                Crud.delete('partners/products',this.product.id)
                .then((res) => {
                    console.log(res)
                    this.$store.dispatch('setLoading',false)
                    this.$store.dispatch('success',`${this.product.name} deleted`)
                    this.$router.go(-1)
                })
                .catch((err) => {
                    console.log(err)
                    this.$store.dispatch('setLoading',false)
                })

            },
              async fetchNext(url) {
                this.$store.dispatch('setLoading',true)
                try {
                    var obj_data = await axios.get(url)
                    var photos = obj_data.data.photos
                    //console.log(photos)
                    const { current_page,last_page, first_page_url, prev_page_url, next_page_url, path} = photos
                    var meta = { last_page, current_page, path}
                    var links = {next_page_url, prev_page_url}
                    this.pagination = this.makePagination(meta, links)
                    this.photos = photos.data

                } catch (error) {
                    console.log(error)
                }
                this.$store.dispatch('setLoading',false)

            },
            async getProduct() {
                this.$store.dispatch('setLoading',true)
                try {
                    var product = await Crud.show('admin/creatives', this.id)
                    this.product = product.data
                    
                    const { current_page,last_page, first_page_url, prev_page_url, next_page_url, path} = product.photos
                    var meta = { last_page, current_page, path}
                    var links = {next_page_url, prev_page_url}
                    this.pagination = this.makePagination(meta, links)
                    this.photos = product.photos.data
                
                    this.$store.dispatch('setLoading',false)
                   
                } catch (e) {
                    this.$store.dispatch('setLoading',false)

                }
            },
            route(path) {
                this.$router.push(path)
            },
        },
        created() {
            this.getProduct()
            //this.$store.dispatch('setLoading',true)

        }
    }
</script>

<style lang="scss" scoped>

</style>